(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  // Check if user allows cookies
  CheckCookie();
  $('.c-numbers__slider').slick({
    dots: false,
    infinite: false,
    centerMode: false,
    speed: 300,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: false,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: 992,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    }]
  }); // Lightbox

  $('.venobox').venobox(); // Accordion

  $('.accordion-button').click(function () {
    if ($(this).closest('.c-accordions').hasClass('open-all')) {
      $(this).toggleClass('open').next().slideToggle(300);
    } else {
      $(this).closest('.c-accordions').find('.accordion-button.open').not(this).removeClass('open').next().slideUp(300);
      $(this).toggleClass('open').next().slideToggle(300);
    }
  }); // Cookies

  $("#cookieButton").click(function () {
    $.cookie('cookiesAccepted', '1', {
      expires: 365,
      path: '/'
    });
    $("#cookieConsent").hide(400);
    CheckCookie();
  });
  $("#closeCookieConsent").click(function () {
    $.cookie('cookiesAccepted', '0', {
      expires: 7,
      path: '/'
    });
    $("#cookieConsent").hide(400);
    CheckCookie();
  }); // Check cookie status

  function CheckCookie() {
    if ($.cookie('cookiesAccepted') == null) {
      // Not set, show Cookie Consent
      $("#cookieConsent").fadeIn(400);
    } else if ($.cookie('cookiesAccepted') == "1") {
      // Accepted, add Google Analytics
      var s = document.createElement("script"),
          x = document.createElement("script");
      s.type = "text/javascript";
      s.src = "/assets/analytics.js";
      x.async = true;
      x.src = "https://www.googletagmanager.com/gtag/js?id=G-WSFSLZ1YNY";
      $("head").prepend(s);
      $("head").prepend(x);
    } else if ($.cookie('cookiesAccepted') == "0") {// Denied, will show again in a week
    }
  } // Animate on Scroll


  AOS.init({
    once: true
  });
});

},{}]},{},[1]);
